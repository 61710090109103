.tableSeparateBorder {
    border-collapse: separate;
}

.tableFullWidth {
    width: 100%;
}

/*region Table Row*/
.tableRowClickable {
    cursor: pointer;
}

.tableRowSelected {
    padding-top: 1px;
    outline: 1px solid var(--dark-primary-main);
    outline-offset: -1px;
}

/*endregion*/

.tableCellWithCheck {
    display: flex;
    gap: 10px;
    width: 100%;
}

.tableCellWithCheck > *:nth-child(2) {
    flex: 1;
}

.tableCellDrag {
    background-color: var(--background-6);
}

.iconSortable {
    margin-left: 10px;
    vertical-align: middle;
}

.headerIconActive {
    fill: var(--text-accent);
    stroke: var(--text-accent);
}

.headerIcon:hover {
    fill: var(--text-accent);
    stroke: var(--text-accent);
}

.iconsContainer {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 5px;
}

.resizeIcon {
    position: relative;
    height: 15px;
}

.resizeBorder {
    --delimiter-width: 2px;

    position: absolute;
    top: 0;
    left: calc(50% - var(--delimiter-width) / 2);
    height: 100vh;
    width: var(--delimiter-width);
    background-color: var(--text-accent);
}

/*region Searchable Header Cell*/
.searchCell {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 10px;
}

.searchCellIconActive {
    fill: var(--text-accent);
    stroke: var(--text-accent);
}

.searchInput {
    background-color: transparent;
    outline: none;
    border: none;
    color: var(--text-accent);
    font-size: 13px;
    width: 100%;
}

.searchInput::placeholder {
    color: var(--text-secondary);
}

.noElements {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

/*endregion*/

