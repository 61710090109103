.container {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    word-break: break-all;
    min-width: 80px;
}

.containerArrow {
    flex-shrink: 0;
}