.paragraph {
    font-family: "Roboto", sans-serif;
    color: var(--dark-white);
    font-weight: 400;
    text-decoration: none;
}

/*region Header*/
.headerH1 {
    composes: paragraph;
    font-weight: 800;
    font-size: 40px;
    line-height: 47px;
}

.headerH2 {
    composes: paragraph;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
}

.headerH3 {
    composes: paragraph;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
}

.headerH4 {
    composes: paragraph;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
}

/*endregion*/

/*region Paragraph*/
.paragraphLargeP {
    composes: paragraph;
    font-size: 18px;
}

.paragraphDefaultP {
    composes: paragraph;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.paragraphDefaultPDef {
    composes: paragraph;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
}

.paragraphSmallP {
    composes: paragraph;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
}

/*endregion*/

/*region Button*/
.buttonSmall {
    composes: paragraph;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.buttonLarge {
    composes: paragraph;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

/*endregion*/

.colorfullP {
    overflow-wrap: anywhere;
}
